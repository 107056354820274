.yoggl-admin-table {
  --yoggl-table-line: 1px solid var(--ion-color-light-shade);

  .yoggl-admin-table-row {
    border-bottom: var(--yoggl-table-line);
  }

  .yoggl-admin-table-row:hover {
    background: var(--ion-color-light-tint);
  }

  .yoggl-admin-table-row .large-content-col {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .yoggl-admin-table-head {
    border-bottom: var(--yoggl-table-line);
    padding-bottom: var(--padding-bottom);
  }
}

.yoggl-admin-table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;

  th, td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  @media screen and (max-width: 1600px) {
    display: block;

  }


}
